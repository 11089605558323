import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, Outlet } from "react-router-dom";

//auth
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import ForgotPswd from "./components/auth/ForgotPswd";
import ResetPswd from "./components/auth/ResetPswd";
//routes
import ProtectedRoute from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
//pages
import CustomerPortal1 from "./components/pages/CustomerPortal1";
import CustomerPortal from "./components/pages/CustomerPortal";
import RewardsHistory from "./components/pages/RewardsHistory";
import ChooseRewards from "./components/pages/ChooseRewards";
import NotFound from "./components/pages/NotFound";
import Header from "./components/layout/Header";
import { getMerchantAndLogo } from "./services/services";
import { Token_Generator } from "./services/auth";
import { encrypt } from "./utils/encodingdecoding";
import Loader from "./components/partial/Loader";
import Offer from "./components/auth/Offer";
import ChooseRewards1 from "./components/pages/ChooseRewards1";
// import RedeemCard from "./components/pages/RedeemCard";
import Otp from "./components/auth/Otp";
import RecieptResponse from "./components/pages/RecieptResponse";

const App = () => {
    // const fetchThemeData = async () => {

    //     let tempToken = JSON.parse(localStorage.getItem("token_gen"));
    //     if (tempToken?.access_token) {
    //         tempToken = tempToken?.access_token;
    //     } else {
    //         setLoader(true);
    //         const token = await Token_Generator();
    //         const Id = localStorage.getItem("Id")

    //         await getMerchantAndLogo(token.access_token, merchantIds ? merchantIds : Id).then((value) => {

    //             setFavicon(value[0]?.blobFilePath);
    //             setLoader(false);
    //         });

    // }
    // };
    // const setFavicon = (imageSrc) => {
    //     const favicon = document.createElement("link");
    //     favicon.rel = "icon";
    //     favicon.type = "image/png";
    //     f

    //     useEffect(() => {
    //         fetchThemeData();
    //         // eslint-disable-next-line
    //     }, []);avicon.href = imageSrc;
    //     document.head.appendChild(favicon);
    // };

    // useEffect(() => {
    //     fetchThemeData();
    //     // eslint-disable-next-line
    // }, []);

    const HeaderLayout = () => (
        <Header>
            <Outlet />
        </Header>
    );

    return (
        <>
            {/* {loader && <Loader />} */}

            <Routes>
                <Route element={<HeaderLayout />}>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <CustomerPortal1 />
                            </ProtectedRoute>
                        }
                    />
                    {/* <Route
                            path="/customer-portal"
                            element={
                                <ProtectedRoute>
                                    <CustomerPortal />
                                </ProtectedRoute>
                            }
                        /> */}

                    <Route
                        path="/customer-portal"
                        element={
                            <ProtectedRoute>
                                <CustomerPortal1 />
                            </ProtectedRoute>
                        }
                    />

                    {/* <Route
                            path="/Rewards-history"
                            element={
                                <ProtectedRoute>
                                    <RewardsHistory />
                                </ProtectedRoute>
                            }
                        /> */}

                    {/* <Route
                            path="/Choose-Rewards"
                            element={
                                <ProtectedRoute>
                                    <ChooseRewards />
                                </ProtectedRoute>
                            }
                        /> */}
                    <Route
                        path="/Choose-Rewards"
                        element={
                            <ProtectedRoute>
                                <ChooseRewards1 />
                            </ProtectedRoute>
                        }
                    />
                     <Route
                        path="/reciept"
                        element={
                            <ProtectedRoute>
                                <RecieptResponse />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="*"
                        element={
                            <ProtectedRoute>
                                <NotFound />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                {/* <Route
                        path="/"
                        element={
                            <PublicRoute>
                                <SignIn />
                            </PublicRoute>
                        }
                    /> */}
                <Route
                    path="/signin"
                    element={
                        <PublicRoute>
                            <SignIn />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/auth/sso-login"
                    element={
                        <PublicRoute>
                            <SignIn />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/signup"
                    element={
                        <PublicRoute>
                            <SignUp />
                        </PublicRoute>
                    }
                />

                <Route
                    path="/forgot-password"
                    element={
                        <PublicRoute>
                            <ForgotPswd />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/otp-verification"
                    element={
                        <PublicRoute>
                            <Otp />
                        </PublicRoute>
                    }
                />

                <Route
                    path="/offer"
                    element={
                        <PublicRoute>
                            <Offer />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/reset-password"
                    element={
                        <PublicRoute>
                            <ResetPswd />
                        </PublicRoute>
                    }
                />
            </Routes>
        </>
    );
};

const WrappedApp = () => (
    <Router>
        <App />
    </Router>
);

export default WrappedApp;
